* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Revista', sans-serif;
} 

@font-face {
  font-family: 'Revista';
  font-style: normal;
  src: url('./fonts/Revista-Regular.eot');
  src: local(''),
       url('./fonts/Revista-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Revista-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Revista-Regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Revista-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}